import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/index.module.css"

const IndexPage = () => (
  <Layout>
    <div className="container">
      <div className="row">
        <div className="col">
          <h2 className="uppercase text-center">
            Welcome to a social experiment based on the evolution of blockchain,
            digital currencies and art. As we evolve forward as a digital
            society, we bring you Gnar Donk NFTs and their ecosystem.
          </h2>
        </div>
      </div>
      <div className="row mt-60">
        <div className="col">
          <h2 className="uppercase text-center text-underline">
            Join us as we consume the future
          </h2>
        </div>
      </div>
      <div className="row mt-60">
        <div className="col">
          <div
            className="d-sm-flex justify-content-around"
            style={{ gap: "10px" }}
          >
            <div className="text-center mb-60">
              <h3 className="uppercase">
                MINT NOW TO <br />
                GAIN ACCESS
              </h3>
              <a
                href="https://gnardonks.com/"
                target="_blank"
                className="btn btn-dark font-interstate weight-700"
              >
                JOIN THE CLUB
              </a>
            </div>
            <div className="text-center mb-60">
              <h3 className="uppercase">
                I JUST WANT <br />
                COFFEE
              </h3>
              <a
                href="https://simplebeans.co/"
                target="_blank"
                className="btn btn-dark font-interstate weight-700"
              >
                BUY BEANS
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Home" />

export default IndexPage
